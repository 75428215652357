import React from "react";
import StoreButtons from "./StoreButtons";

const Descargar = () => {
  return (
    <div id="descarga">
      <StoreButtons />
    </div>
  );
};

export default Descargar;
